@import "@/libscss/sites/syndication/variables/_bootstrap-custom"; @import "@/libscss/sites/syndication/variables/_globals";

/* required class */
.prism-editor-wrapper .prism-editor__editor,
.prism-editor-wrapper .prism-editor__textarea {
  overflow: hidden !important;
}
.xml-editor {
  font-family: "Radio-Canada", sans-serif;
  line-height: 1.3em;

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
}
