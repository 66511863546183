// Page
// Links
// Title levels
// Basics
// Buttons
// Dropdown menu
// Intro
// Forms
// Filters
// Filter Unit
// Unit
// Item Summary
// Tabs horizontal
// Tabs vertical
// Modals
// Tooltip

// Page
//___________________________________________________
html,
body,
#app,
.rc-loader,
.rc-wrapper {
  position: relative;
  height: 100%;
}

body {
  color: #000000;
  font-family: "Radio-Canada", sans-serif;
  overflow-x: hidden;
  line-height: 1.3em;

  &.no-scroll {
    overflow: hidden;
  }
}

.rc-page-header,
.rc-page-body,
.rc-page-footer {
  position: relative;
}
.rc-page-header {
  .rc-back-trigger + & {
    margin-top: 16px;
  }
}

.rc-page-footer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: $h6-font-size;
    a {
      text-decoration: underline;
    }
  }
}

// Links
//___________________________________________________
a {
  color: var(--black);
  &:hover {
    color: $gray-700;
  }
}
a[role="button"] {
  border-radius: $border-radius;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.125rem #fff,
      0 0 0 calc(0.125rem * 2) $input-btn-focus-color;
  }
  &:hover,
  &:active {
    border: 2px solid $gray-300;
  }
}
.rc-back-trigger {
  margin-top: -16px;
  position: relative;
  cursor: pointer;
  background: url(./assets/img/ic_back.svg) center center no-repeat transparent;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: block;
  text-indent: -9999px;
}
.rc-close-trigger {
  position: relative;
  cursor: pointer;
  background: url(./assets/img/ic_close.svg) center center no-repeat transparent;
  background-size: 18px;
  width: 24px;
  height: 24px;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
}
.rc-menu-trigger {
  position: relative;
  cursor: pointer;
  background: url(./assets/img/ic_context_menu.svg) center center no-repeat
    transparent;
  background-size: 4px;
  width: 24px;
  height: 24px;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
}
.rc-more-trigger {
  position: relative;
  cursor: pointer;
  background: url(./assets/img/ic_dropdown.svg) center bottom no-repeat
    transparent;
  background-size: 12px;
  width: 20px;
  height: 20px;
  display: block;
  text-indent: -9999px;
  transform: rotate(-90deg);
  overflow: hidden;
}
.rc-cog-trigger {
  position: relative;
  cursor: pointer;
  background: url(./assets/img/ic_cog.svg) center center no-repeat transparent;
  background-size: 16px;
  width: 20px;
  height: 20px;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  .dropdown-toggle & {
    margin-right: -4px;
    margin-left: -2px;
  }
}

// Title levels
//___________________________________________________
h1 {
  font-size: $h1-font-size;
  line-height: 1.1em;
  font-weight: 500;
  letter-spacing: -0.03em;
}

// Basics
//___________________________________________________
address {
  margin: 0;
}
section {
  margin: 0 0 24px;
}

// Buttons
//___________________________________________________
// .btn-primary,
// .btn-primary.disabled {
//   background: var(--button-background-primary) !important;
// }

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle {
  color: var(--black) !important;
  background: none #ffffff !important;
  border: 2px solid $gray-300 !important;
}

.btn-sm {
  padding: 0.2rem 1.6rem 0.3rem;
}

.btn-lg {
  padding: 0.365rem 1.6rem 0.575rem;
}

.btn {
  border: 2px solid transparent;
  &:hover,
  &:active {
    border: 2px solid $gray-300;
  }
}

.btn-icon {
  line-height: 1;
  height: 40px;
  width: 40px;
  padding: 0;
  border: 2px solid transparent !important;
  &:hover,
  &:active {
    border: 2px solid $gray-300 !important;
  }
}

// Dropdown menu
//___________________________________________________
.dropdown-toggle {
  height: 38px;
  padding: 7px 32px 10px 12px;
  line-height: 1em;
  &:before {
    content: "";
    padding: 0;
    border: 0 none;
    background: url(./assets/img/ic_dropdown.svg) no-repeat center top
      transparent;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -7px;
    width: 14px;
    height: 14px;
  }
}
.dropdown-menu {
  z-index: 1029;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  border-radius: $border-radius;
  li {
    a {
      border-left: 4px solid transparent;
      color: var(--black);
      padding: 12px 16px 12px 12px;
      font-size: $font-size-sm;
      line-height: 1.1;
      &:hover {
        background: #fafafa;
        color: var(--black);
      }
      &.active {
        font-weight: normal;
        background: #f5f5f5;
        border-left: 4px solid var(--primary);
        color: var(--black);
      }
      &.disabled,
      &:disabled {
        color: var(--text-muted);
      }
    }
    hr {
      margin: 0;
    }
    &:first-child a {
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
    &:last-child a {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }
}

.rc-item-options {
  .dropdown-toggle {
    line-height: 1;
    height: 40px;
    width: 40px;
    padding: 0;

    &:before {
      display: none;
    }
    .rc-menu-trigger {
      width: 36px;
      height: 36px;
    }
  }
}

// Intro
//___________________________________________________
.rc-intro {
  font-size: $h6-font-size;
  line-height: 1.25;
  margin-bottom: 1em;
}

.rc-loading-message {
  color: var(--text-muted);
}

.rc-empty-message {
  padding-top: 60px;
  color: var(--black);
  font-size: $h4-font-size;
  font-weight: 500;
  text-align: center;
}

// Badges
//___________________________________________________

.badge {
  text-transform: uppercase;
  & + .badge {
    margin-left: 10px;
  }
  &-progress {
    background-color: var(--blue);
    color: #ffffff;
  }
  &-standby {
    background-color: #ffffff;
    border: 2px solid var(--blue);
    color: var(--blue);
    padding: 3px 8px;
  }
  &-warning {
    color: #ffffff;
  }
}

// Forms
//___________________________________________________

.row {
  &.rc-form-group-plaintext {
    margin: 0;
  }
  &:last-child {
    margin-bottom: 0;
    border: 0 none;
  }

  label {
    font-weight: 500;
  }
}
.rc-action-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  .btn {
    margin-left: 16px;
  }
  .card & {
    justify-content: space-between;
    margin-left: calc(0px - var(--x-padding));
    margin-right: calc(0px - var(--x-padding));
    border-top: var(--rc-border-base);
    padding: calc(var(--y-padding) / 2) var(--x-padding);
    margin-top: var(--y-padding);
    .btn {
      margin: 0;
    }
  }
}

// Filters
//___________________________________________________
.rc-filter {
  margin-bottom: 1em;
  label {
    display: block;
    margin-bottom: 4px;
    font-weight: 500;
    a,
    button {
      float: right;
      font-size: $font-size-sm;
      color: var(--text-muted);
      font-weight: normal;
      text-decoration: none;
      padding: 0;
      border: 0;
      &:hover {
        border: 0;
        padding: 0;
      }
    }
  }
  .multiselect {
    min-height: 38px;
    height: 38px;
    color: var(--black);
  }
  .multiselect__tags {
    height: 38px;
    min-height: 38px;
    cursor: pointer;
    border-radius: $border-radius;
    border: 2px solid $gray-300;
    background: #ffffff;
    font-size: $font-size-base;
    line-height: 1em;
    padding: 7px 32px 10px 12px;
  }
  .multiselect__select {
    content: "";
    padding: 0;
    border: 0 none;
    background: url(./assets/img/ic_dropdown.svg) no-repeat center top
      transparent;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -7px;
    width: 14px;
    height: 14px;
    overflow: hidden;
    text-indent: -999px;
  }
  .multiselect__input,
  .multiselect__single,
  .multiselect__placeholder {
    padding: 0;
    margin: 0;
    font-size: $font-size-base;
    line-height: 1.1em;
  }
  .multiselect__input {
    position: relative;
  }
  .multiselect__single {
    max-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .multiselect__content-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    top: 40px;
    left: 0;
    width: auto;
    min-width: 100%;
    max-width: 100%;
  }
  .multiselect--above .multiselect__content-wrapper {
    top: auto;
  }
  .multiselect__option {
    font-size: $font-size-sm;
    border-left: 4px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .badge {
      border: 0 none;
      background: #f5f5f5;
      color: var(--black);
      margin-left: 10px;
    }
    &--highlight {
      background: #fafafa;
      color: var(--black);
    }
    &--selected {
      font-weight: normal;
      background: #f5f5f5;
      border-left: 4px solid var(--primary);
    }
  }
  .multiselect--active .multiselect__select {
    transform: unset;
  }
  .multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

// Filter Unit
//___________________________________________________
.rc-filter-unit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Unit
//___________________________________________________
.rc-unit {
  cursor: text !important;
  border: 0 none;
  background: none transparent;
  border-bottom: thin solid #ddd;
  margin: 0;
  padding: var(--y-padding) var(--x-padding);
  &.rc-unit-link {
    cursor: pointer !important;
    &:hover > div > .row,
    &:hover > .row {
      background: none #f9f9f9;
    }
  }
  .row {
    margin-left: calc(0px - var(--x-padding));
    margin-right: calc(0px - var(--x-padding));
    border-radius: $border-radius;
  }
  .col {
    padding: 0 var(--x-padding);
  }
  .col-auto {
    flex: initial;
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      padding-right: var(--x-padding);
    }
    &.col-btn {
      padding-right: 8px;
      .btn {
        padding: 0;
        height: auto;
      }
    }
  }
  &:hover {
    cursor: pointer;
    .rc-timeline-wrapper {
      .rc-unit-timeline-trigger {
        opacity: 1;
      }
    }
  }
  &.active {
    background: transparent;
    border-color: #ddd;
    & > div > .row,
    & > .row {
      color: #000;
      background: none #f4f4f4;
    }
  }
  &:last-child {
    border-radius: 0;
  }
}
.rc-unit-cell {
  padding: 10px 0 12px;
  span:not(.badge) {
    margin-top: 0.1em;
    font-size: $font-size-sm;
    line-height: 1.3em;
    color: $gray-600;
  }

  > span:not(.badge) {
    display: block;
  }

  &.rc-unit-options {
    width: 24px;
    margin-left: 16px;
    padding: 8px 0 10px 0;
    .dropdown,
    .dropdown .btn {
      padding: 0;
      width: 24px;
    }
    .dropdown {
      display: inline-block;
    }
  }
  &.rc-unit-batch {
    padding-left: 16px;
    width: 30px;
  }
  &.rc-unit-status {
    text-align: center;
    .badge {
      position: relative;
    }
  }
  &.rc-unit-timeline {
    padding-left: 20px;
    position: relative;
    &:before {
      top: calc(0px - var(--y-padding));
      bottom: calc(0px - var(--y-padding));
    }
    .badge.badge-pill {
      top: calc(var(--y-padding) + 1em);
    }
  }
}

// Unit header
//___________________________________________________
.rc-unit-cell-header {
  margin: 0;
  padding: 0;
  padding: var(--y-padding) var(--x-padding);
  border: 0 none;
  background: #f4f4f4;
  border-radius: $border-radius;
  color: #999;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  .row {
    margin-left: calc(0px - var(--x-padding));
    margin-right: calc(0px - var(--x-padding));
  }
  .col {
    padding: 0 var(--x-padding);
  }
  .col-auto {
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      padding-right: var(--x-padding);
    }
  }
  .rc-unit-cell {
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 0;
    span {
      // Because spans are used for translation
      color: #999;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

// Item Summary
//___________________________________________________
.rc-item-summary {
  padding-right: 40px;
  .rc-item-options {
    float: right;
    margin-right: -40px;
    .btn {
      padding: 0;
    }
  }
  h1 {
    word-break: break-all;
    font-size: $h6-font-size;
    line-height: 1.2em;
    margin-bottom: 10px;
    & + p {
      margin-top: -8px;
      font-size: $font-size-base;
      line-height: 1.3em;
      color: $gray-600;
      margin-bottom: 11px;
    }
  }
}

// Tabs Horizontal
//__________________________________________________
.rc-tabs-horizontal {
  > div > .nav-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    border: 0 none;
    border-bottom: var(--rc-border-base);
    .nav-item {
      .nav-link {
        border: 0 none;
        border-bottom: 5px solid transparent;
        border-radius: 0;
        color: var(--black);
        font-size: $h5-font-size;
        padding: 8px 2px 14px;
        margin: 0 16px;
        &.active {
          color: var(--black);
          border-color: var(--primary);
          background: none transparent;
        }
        &.disabled {
          color: var(--text-muted);
        }
      }
    }
  }
  > .tab-content {
    padding-top: 24px;
  }
}

// Tabs vertical
//__________________________________________________
.rc-tabs-vertical {
  .nav-tabs {
    border: 0 none;
    border-left: var(--rc-border-base);
    .nav-item {
      .nav-link {
        border: 0 none;
        border-left: 5px solid transparent;
        border-radius: 0;
        color: var(--black);
        font-size: $font-size-sm;
        min-width: 130px;
        position: relative;
        left: -1px;
        &.active {
          color: var(--black);
          border-color: var(--primary);
          background: none #f5f5f5;
        }
        &.disabled {
          color: var(--text-muted);
        }
      }
    }
  }
  .tab-content {
    padding-bottom: 48px;
  }
}

// Collapse
//__________________________________________________
.rc-collapse-wrapper {
  --left-padding: 16px;
  margin-left: calc(0px - var(--x-padding));
  margin-right: calc(0px - var(--x-padding));
}
.rc-collapse-trigger {
  position: relative;
  padding: 4px 0 6px var(--left-padding);
  margin-bottom: 0;
  font-size: $font-size-base;
  line-height: 1.3em;
  text-transform: uppercase;
  cursor: pointer;
  &:before {
    content: "";
    width: 8px;
    height: 11px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
    background: url(./assets/img/ic_toggle.png) no-repeat center center
      transparent;
    background-size: 7px;
  }
  &:not(.collapsed) {
    &:before {
      left: -1px;
      transform: rotateY(0deg) rotate(90deg); /* needs Y at 0 deg to behave properly*/
      transition: transform 0s;
    }
  }
}
.rc-collapse-content {
  padding: var(--x-padding) 0 var(--x-padding) var(--left-padding);
  .rc-unit {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

// Modals
//__________________________________________________
.modal-dialog {
  .modal-header {
    .modal-title {
      font-size: 2rem;
      font-weight: 500;
    }
    .close {
      position: relative;
      padding: 0;
      margin: 0.25rem 0 0 auto;
      width: 2rem;
      height: 2rem;
      border-radius: $btn-border-radius;
      text-indent: -9999px;
      overflow: hidden;
      opacity: 1;
      outline: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 1.5rem;
        height: 0.125rem;
        left: 50%;
        top: 50%;
        background-color: currentColor;
        transition: transform 0.2s linear;
      }
      &:before {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
      &:after {
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
      &:not(:disabled):not(.disabled):hover,
      &:not(:disabled):not(.disabled):focus {
        opacity: 1;
      }
    }
  }
  .modal-body {
    .rc-thumbnail-wrapper {
      float: right;
      border: var(--rc-border-base);
      background-color: #fff;
      min-height: 200px;
      min-width: 355px;
      max-width: 400px;
      display: flex;
      align-items: center;
      p {
        color: var(--text-muted);
        text-align: center;
        width: 50%;
        margin: 0 auto;
      }
    }
    h2 {
      margin-top: 21px;
      font-size: $h5-font-size;
    }
    .rc-item-summary + .rc-data-list {
      margin-top: 16px;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
}
.modal-dialog-scrollable {
  .modal-header {
    padding-bottom: 1rem;
    z-index: 1;
  }
  .modal-body {
    z-index: 2;
    border-top: var(--rc-border-base);
    padding-top: 40px;
    padding-bottom: 40px;
    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        0deg,
        rgba(#ffffff, 1) 0%,
        rgba(#fafafa, 1) 100%
      );
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 300px;
      z-index: 0;
    }
    .tabs {
      position: relative;
    }
  }
}
.modal-full-screen {
  max-width: 95vw;
  height: 95vh;
  .modal-body {
    padding-top: 40px;
  }
  .rc-tabs-horizontal {
    > div > .nav-tabs {
      border: 0 none;
      position: fixed;
      top: 54px;
      left: 50%;
      width: 600px;
      margin-left: -300px;
    }
    > .tab-content {
      padding-top: 0;
    }
  }
}

// Error pages
//__________________________________________________
.rc-error-page {
  text-align: center;
  h1 {
    font-size: 120px;
    line-height: 120px;
    margin-bottom: 24px;
    span {
      letter-spacing: 0;
      font-weight: normal;
      font-size: $h4-font-size;
      line-height: 1em;
      display: block;
    }
  }
  .rc-intro {
    .btn {
      margin-top: var(--y-padding);
    }
  }
}

// Utils
.rc-ellipsis {
  white-space: nowrap;
  position: relative;
  left: 0px;
  transform: translateX(0%);
  transition: all 20s ease;
  overflow: hidden;
  &:not(:hover) {
    text-overflow: ellipsis;
    transition: all 0.5s ease;
  }
  &-active:hover {
    transform: translateX(-150%);
    text-overflow: unset;
  }
}

//Accessibility

.rc-focusable[tabindex="0"]:focus {
  outline: 0;
  .rc-text-focusable {
    text-decoration: underline;
  }
}

// Tooltip
.tooltip {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    text-align: left;
  }
}
