// ------------------------------------------
// UNITS
//-------------------------------------------
// ------------------------------------------
// Specific to Row Unit (look like table) (used for teams, memberships)
//-------------------------------------------
.rc-row-unit {
  h3,
  .h3 {
    margin-bottom: 0.2rem;
    font-size: $h5-font-size;
    line-height: $headings-line-height;
    font-weight: $font-weight-base;
  }

  .rc-col-status {
    text-align: right;

    .btn {
      font-size: toRem(15px);
      min-width: 7rem;
    }
    .badge {
      display: inline-block;
      margin-top: -5px;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      text-transform: capitalize;
      line-height: 1.25;
      min-width: 7rem;
      min-height: 1.75rem;
    }
  }
  .rc-col-dd-actions {
    text-align: center;
    padding-right: 0;
    .rc-dd-actions {
      padding: 0.325rem 0;
    }
  }
}
