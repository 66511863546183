// ------------------------------------------
// FORM
//-------------------------------------------

// ------------------------------------------
// DROPDOWN (new, used in teams pages)
//-------------------------------------------
.dropdown,
.rc-btn-toggle {
  .rc-chevron-up-bold,
  .rc-chevron-down-bold {
    //margin: 0.1rem -0.2rem 0 0.5rem;
    margin: 0 -0.2rem 0 0.5rem;
  }
  .rc-chevron-up-bold {
    display: none;
  }
  .rc-chevron-down-bold {
    display: inline-block;
  }

  &.show,
  &[aria-expanded="true"] {
    .rc-chevron-up-bold {
      display: inline-block;
    }
    .rc-chevron-down-bold {
      display: none;
    }
  }
}

// ------------------------------------------
// COMMON
//-------------------------------------------
// Same focus everywhere
.modal-header .close:focus,
.btn:focus,
.btn.focus,
.custom-select:focus,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: $input-btn-focus-box-shadow !important;
}

// Remove green label on valid custom-controls (radio and checkbox)
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: inherit !important;
}

// Remove green input on valid custom-controls (radio and checkbox)
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: $component-active-bg !important;
  background-color: $component-active-bg !important;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: $component-active-bg !important;
}
