
@font-face {
  font-family: 'Northwell Alt';
  src: url('~@/libscss/assets/fonts/Northwell/NorthwellAlt.woff2') format('woff2'),
  url('~@/libscss/assets/fonts/Northwell/NorthwellAlt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Northwell';
  src: url('~@/libscss/assets/fonts/Northwell/Northwell.woff2') format('woff2'),
  url('~@/libscss/assets/fonts/Northwell/Northwell.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Webfont: Radio-Canada
@font-face {
  font-family: "Radio-Canada";
  src: url('#{$base-font-path}radiocanada/radio-canada-regular.woff2') format("woff2"),
      url('#{$base-font-path}radiocanada/radio-canada-regular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Radio-Canada";
  src: url('#{$base-font-path}radiocanada/radio-canada-medium.woff2') format("woff2"),
      url('#{$base-font-path}radiocanada/radio-canada-medium.woff') format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Radio-Canada";
  src: url('#{$base-font-path}radiocanada/radio-canada-bold.woff2') format("woff2"),
      url('#{$base-font-path}radiocanada/radio-canada-bold.woff') format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
