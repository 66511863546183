@import "@/libscss/sites/syndication/variables/_bootstrap-custom"; @import "@/libscss/sites/syndication/variables/_globals";

//Properties

:root {
  --rc-auth-status-bar-height: 38px;
}

.rc-auth-status-bar {
  --component-background-color: #000;
  --component-text-color: #fff;
  --component-font-size: #{$small-font-size};
  --component-line-height: calc(var(--component-font-size) * 1.3);
  --component-height: auto;
  --component-padding-x: 16px;
  --component-padding-y: 8px;
  --component-link-color: #fff;
  --component-link-hover-color: var(--component-link-color);

  @media (min-width: $screen-md-min) {
    --component-height: var(--rc-auth-status-bar-height);
  }
}

// Styles

.rc-auth-status-bar {
  position: relative;
  height: var(--component-height);
  padding: var(--component-padding-y) var(--component-padding-x);
  background-color: var(--component-background-color);
  color: var(--component-text-color);
  font-size: var(--component-font-size);
  line-height: var(--component-line-height);

  ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  a {
    text-decoration: underline;
    color: var(--component-link-color);
    &:hover {
      color: var(--component-link-color-hover);
    }
  }
}

@media (min-width: $screen-md-min) {
  .rc-auth-status-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 16;
    width: 100%;

    ul {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }
  }

  //needed for sticky
  .rc-wrapper:not(.rc-playlist-detail-layout) {
    overflow: unset !important;
  }
}
