@import "@/libscss/sites/syndication/variables/_bootstrap-custom"; @import "@/libscss/sites/syndication/variables/_globals";

.rc-icon-svg {
  display: inline-block;
  overflow: visible;
}

//Bootstrap:  Make icons slightly larger in buttons, nav-links, dropdowns, and input-group-text
.btn,
.nav-link,
.dropdown-toggle,
.dropdown-item,
.input-group-text {
  .rc-icon-svg {
    font-size: 125%;
    vertical-align: text-bottom;
  }
}
