// Header
// Status bar
// Main container
// Footer
// Drawer
// Application
// Page header
// List
// Team list
// Data list

// Header
//___________________________________________________
.site-header {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $gray-300;
  .navbar {
    margin: 0;
    padding: 0;
    height: var(--header-height);
    .navbar-brand {
      margin: 0;
      padding: 0;
      background: url(./assets/img/lg_conduit.png) no-repeat center center
        transparent;
      background-size: 160px;
      width: 160px;
      height: var(--header-height);
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -80px;
      text-indent: -9999px;
      overflow: hidden;
      display: block;
    }
    .navbar-nav {
      align-items: center;
    }
    .rc-team-trigger {
      display: block;
      & > .nav-link {
        height: auto;
        min-width: 120px;
        position: relative;
        text-align: left;
        background: none #ffffff;
        border: 2px solid $gray-300;
        border-radius: $border-radius;
        color: var(--black);
        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
        padding: 4px 32px 5px 8px !important;
        span {
          font-size: 12px;
          line-height: 13px;
        }
        &::before {
          top: auto;
          bottom: 5px;
          right: 10px;
        }
      }
      .rc-link-all-teams {
        border-top: var(--rc-border-base);
      }
    }
    .rc-user-trigger {
      margin: 0;
      padding: 0;
      position: relative;
      top: 0;
      right: -5px;
      & > .nav-link {
        margin: 0;
        padding: 0;
        display: block;
        background: url(./assets/img/ic_user.svg) no-repeat center center
          transparent;
        background-size: 30px 30px;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
        overflow: hidden;
        &::before {
          display: none;
        }
      }
      & + .rc-team-trigger {
        margin-left: 24px;
      }
    }
    .rc-signin-trigger {
      display: flex;
      align-items: center;
      padding: 0;

      & > .nav-link {
        font-size: $font-size-base;
        line-height: 1.25;
        text-align: center;
        background: none #ffffff;
        border: 2px solid $gray-300;
        border-radius: $border-radius;
        color: var(--black);
        padding: 0.265rem 1.5rem 0.375rem;
      }
    }
  }
}

// Auth Status Bar
//___________________________________________________

.rc-auth-status-bar {
  --component-background-color: #343434;
}

// Main container
//___________________________________________________
.main-container {
  position: relative;
  min-height: calc(
    100% - calc(var(--header-height) + 1px)
  ); // Add 1px to header height because of its default bottom border
  margin-bottom: calc(0px - var(--footer-height));
  // &::before {
  //   content: "";
  //   display: block;
  //   background: linear-gradient(
  //     0deg,
  //     rgba(#ffffff, 1) 0%,
  //     rgba(#fcfcfc, 1) 100%
  //   );
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   height: 300px;
  //   z-index: 0;
  // }
  &:after {
    content: "";
    display: block;
    height: var(--footer-height);
  }
}

//
.rc-content-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 450px;

  &.h-100 {
    max-height: unset;
  }
}

// Footer
//___________________________________________________
.site-footer {
  position: relative;
  height: var(--footer-height);
  .rc-copy {
    margin: 0;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    text-align: center;
    color: #aaaaaa;
  }
}

// Search & Filters
//___________________________________________________

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.rc-search-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  height: 80px;
  background: none #ffffff;

  .rc-search {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }
    .rc-clearall-trigger {
      font-size: $font-size-sm;
      color: var(--text-muted);
      margin: 0 20px 0 8px;
      padding: 0.5rem !important;
      border: 0 !important;
      text-decoration: none !important;
      &:hover {
        color: black;
      }
    }

    .form-control {
      width: 200px;
      border: 0 none;
      border-bottom: 2px solid #dddddd;
      border-radius: 0;
      box-shadow: 0 0 0 transparent;
      font-size: $h6-font-size;
      background-color: transparent;
      transition: all 0.5s ease-in-out;
      padding-left: 0;
      padding-right: 28px;
      background: url(./assets/img/ic_search.svg) center right no-repeat;

      &::placeholder {
        font-weight: 500;
        font-style: italic;
        color: var(--text-muted);
      }
      &:focus {
        width: 400px;
      }
    }
  }
  .rc-search-options {
    display: flex;
    align-items: center;
    .rc-clearall-trigger {
      font-size: $font-size-sm;
      color: var(--text-muted);
      margin-right: 20px;
      padding: 0.5rem !important;
      border: 0 !important;
      text-decoration: none !important;
      &:hover {
        color: black;
      }
    }
    .rc-filters-trigger {
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      font-weight: 500;
      text-transform: uppercase;
      .badge {
        margin-right: 6px;
        margin-left: -4px;
        font-size: $font-size-base;
        height: 22px;
        min-width: 22px;
        border-radius: 11px;
        padding: 0.13em 0 0 0;
        top: 0;
      }
    }
  }
}
.rc-filters-wrapper {
  position: relative;
  border-top: var(--rc-border-base);
  background: none #f5f5f5;
  height: 0;
  .rc-filters {
    display: none;
    padding: 20px 0 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: none #ddd;
    }
    &:before {
      // Inset
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 10px;
      box-shadow: var(--rc-inset-default);
    }
  }
  .rc-action-group {
    display: none;
  }
}
.visible {
  .rc-filters-wrapper {
    position: relative;
    height: var(--filters-height-visible);
    .rc-filters {
      display: block;
    }
    .rc-action-group {
      display: flex;
    }
  }
}

// Application header / filters / body
//___________________________________________________
.rc-app-header {
  position: relative;
  transition: var(--rc-transition-default);
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 1020;
  .rc-app-brand {
    display: block;
    text-indent: -9999px;
    overflow: hidden;
    &-flow {
      background: url(./assets/img/lg_flow_sm.png) no-repeat top left
        transparent;
      background-size: 96px 88px;
      width: 96px;
      height: 88px;
    }
    &-delivery {
      background: url(./assets/img/lg_delivery_sm.png) no-repeat top left
        transparent;
      background-size: 153px 88px;
      width: 153px;
      height: 88px;
    }
    &-archive {
      background: url(./assets/img/lg_archive_sm.png) no-repeat top left
        transparent;
      background-size: 135px 88px;
      width: 135px;
      height: 88px;
    }
    &-upload {
      background: url(./assets/img/lg_upload_sm.png) no-repeat top left
        transparent;
      background-size: 135px 88px;
      width: 135px;
      height: 88px;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: none #ddd;
  }
}
.rc-app-filters {
  position: sticky;
  top: 0;
  z-index: 1010;
  .rc-tabs {
    position: absolute;
    top: 16px;
    left: 50%;
    width: 320px;
    margin-left: -160px;
  }
  &:after {
    // Inset
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;
    height: 10px;
    box-shadow: var(--rc-inset-default);
  }
  .card &:after {
    display: none;
  }
}
.rc-app-body {
  padding-top: 30px;
}

// Drawer
//___________________________________________________
.rc-drawer-wrapper {
  min-height: calc(100vh - 180px);
  .rc-drawer {
    overflow-x: hidden;
    width: 30%;
    padding-top: 0;
    background: #f7f7f7; // Should be a property
    border-left: thin solid #ddd; // Should be a property
    ~ .rc-content-root {
      padding-right: 0;
    }
    &.visible {
      right: 0;
      ~ .rc-content-root {
        padding-right: 30%;
      }
    }
  }
  &.fixed {
    background: #ffffff;
    .rc-drawer {
      position: fixed;
      top: 0;
    }
  }
}
.rc-drawer-header {
  border-bottom: thin solid #c8c8c8;
  margin-bottom: 33px;
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(1rem + 1px);
  font-weight: 500;
  color: #999999;
  .rc-drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rc-back-trigger {
    margin-top: 0;
    margin-right: 16px;
    background-size: 20px;
  }
  .rc-drawer-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .rc-menu-trigger {
    margin-right: 10px;
  }
}
.rc-drawer-body {
  .rc-item-summary {
    h1 {
      font-size: $font-size-base;
      & + p {
        font-size: $font-size-sm;
      }
    }
  }
  .rc-list-header {
    margin-bottom: 10px;
    .rc-list-count > span:first-child,
    .rc-list-count > h2 {
      font-size: $font-size-base;
    }
  }
  .list-group {
    margin-bottom: 28px;
    & + div > p {
      margin-top: -16px;
      text-align: center;
      a {
        text-decoration: none;
        color: var(--text-muted);
        font-size: $font-size-sm;
      }
    }
  }
  .rc-unit {
    cursor: default;
    background: #fff;
    border: var(--rc-border-base);
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: -1px;
    &:first-child {
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &-process {
      cursor: pointer;
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & + p {
        word-wrap: break-word;
      }
    }
    .rc-unit-cell {
      word-wrap: break-word;
    }
    .rc-unit-status {
      text-align: right;
      .badge {
        position: relative;
      }
    }
  }
}

// Page header
//___________________________________________________
.rc-page-header {
  display: block;
  position: relative;
}

// List
//___________________________________________________
.rc-list-header {
  margin-bottom: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  .rc-list-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .dropdown-trigger:focus {
      outline: 0 none;
    }
  }
  > :first-child {
    margin-right: auto;
  }
  .rc-sort-wrapper {
    margin-right: 10px;

    label {
      margin: 0 8px 0 0;
    }
    .select2 {
      min-width: 160px;
    }
    .vs__dropdown-toggle {
      padding: 1px 0 5px 0;
      background-color: #fff;
      border: 2px solid $gray-300;
      &:before {
        content: "";
        background: url(./assets/img/ic_dropdown.svg) no-repeat center top
          transparent;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -7px;
        width: 14px;
        height: 14px;
      }
    }
    .vs__dropdown-menu {
      border: 2px solid $gray-300;
      border-top-width: 1px;
    }
    .vs__open-indicator {
      display: none;
    }
  }
  .rc-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 0;
    label {
      white-space: nowrap;
      margin-right: 8px;
    }
    .multiselect__content-wrapper {
      left: auto;
      right: 0;
      max-width: none;
    }
  }
  .dropdown-menu {
    .b-dropdown-text {
      padding: 12px 16px 12px 12px;
      border-left: 4px solid transparent;
    }
    .rc-list-refresh {
      justify-content: space-between;
    }
  }
}

.rc-list-count {
  > span:first-child,
  > h2 {
    font-weight: 500;
    font-size: $h6-font-size;
    margin-bottom: 0;
    & + a,
    & + button {
      margin-left: 8px;
      text-decoration: underline;
    }
  }
}

.rc-refresh-trigger {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(./assets/img/ic_refresh.svg) no-repeat center center
    transparent;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  border: 0;
}

.rc-countdown {
  color: var(--text-muted);
  font-weight: normal;
  font-style: italic;
  font-size: $font-size-sm;
}

.rc-list-refresh {
  .custom-switch .custom-control-label {
    font-size: $font-size-sm;
    &::before {
      top: 2px;
      height: toRem(20px);
    }
    &::after {
      top: 0.375rem;
    }
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.8rem);
  }
}

.rc-list-body {
  .rc-unit {
    border-bottom: var(--rc-border-base);
    .list-group .rc-unit:last-child .rc-unit-cell.rc-unit-timeline:before {
      bottom: auto;
      height: 30px;
    }
    .rc-timeline-wrapper {
      position: relative;
      border-top: 1px solid #ddd;
      margin-left: calc(0px - var(--x-padding));
      margin-right: calc(0px - var(--x-padding));
      .rc-unit-timeline-trigger {
        z-index: 3;
        opacity: 0;
        transition: var(--rc-transition-default);
        position: absolute;
        left: 50%;
        top: -15px;
        width: 30px;
        height: 30px;
        margin: 0 0 0 -15px;
        padding: 0;
        background: none #ffffff;
        color: var(--black);
        border: 1px solid #ddd;
        border-radius: 15px;
        text-align: center;

        &:focus {
          opacity: 1;
        }
      }
      .rc-sibling-collapse.show + .rc-unit-timeline-trigger {
        .icon-mini-close {
          display: block;
          background: url(./assets/img/ic_mini-close.svg) no-repeat center
            center transparent;
          background-size: 8px 8px;
          width: 14px;
          height: 12px;
          text-indent: -9999px;
          overflow: hidden;
          position: relative;
          left: 50%;
          margin-left: -7px;
        }
        .icon-mini-menu {
          display: none;
        }
      }
      .rc-sibling-collapse + .rc-unit-timeline-trigger {
        .icon-mini-menu {
          display: block;
          background: url(./assets/img/ic_mini-menu.svg) no-repeat center center
            transparent;
          background-size: 8px 8px;
          width: 14px;
          height: 12px;
          text-indent: -9999px;
          overflow: hidden;
          position: relative;
          left: 50%;
          margin-left: -7px;
        }
        .icon-mini-close {
          display: none;
        }
      }
      .icon-mini-fetch {
        display: block;
        background: url(./assets/img/ic_mini-menu.svg) no-repeat center center
          transparent;
        background-size: 8px 8px;
        width: 14px;
        height: 12px;
        text-indent: -9999px;
        overflow: hidden;
        position: relative;
        left: 50%;
        margin-left: -7px;
      }
      .rc-unit {
        border: 0 none;
        padding-top: 0;
        &:first-child {
          padding-top: var(--y-padding);
        }
        &:last-child {
          border-bottom: var(--rc-border-base);
          padding-bottom: var(--y-padding);
        }
        .rc-unit-cell {
          color: #999;
          span {
            color: #bbb;
          }
        }
        .rc-unit-timeline {
          border-bottom: 0 none;
          padding-left: 1.3em;
          .badge.badge-pill {
            position: absolute;
            top: 1em;
            left: -7px;
            padding: 0;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            border: 2px solid #fff;
            box-shadow: 0 0 0 2px #ddd;
            text-indent: -9999px;
            overflow: hidden;
          }
          &:before {
            position: absolute;
            left: 0;
            bottom: -10px;
            content: "";
            width: 2px;
            background: #ddd;
          }
        }
      }
      .rc-empty-message {
        margin: 12px 0 18px;
        padding: 0;
        text-align: center;
        color: var(--text-muted);
        font-size: $font-size-base;
        font-weight: normal;
      }
    }
    &:hover {
      cursor: pointer;
      .rc-timeline-wrapper {
        .rc-unit-timeline-trigger {
          opacity: 1;
        }
      }
    }
    &.active {
      .rc-timeline-wrapper {
        .rc-unit-timeline-trigger {
          opacity: 1;
        }
      }
      .rc-unit {
        color: #000;
        &.active {
          background: transparent;
          border: 0 none;
        }
      }
    }
  }
  &.rc-virtual-list {
    //Units have an inline min-height of 84px because reasons...
    min-height: 100vh;
    .rc-unit .rc-timeline-wrapper {
      top: 11px;
    }
    .rc-unit-link > .row {
      min-height: 64px;
    }
  }
}

// Team List
//___________________________________________________
.rc-team-list {
  .dropdown-toggle {
    padding: 0.25rem 0.5rem;

    &::before {
      display: none;
    }
  }
}

// Data List
//___________________________________________________
.rc-data-list {
  margin-bottom: 48px;
  & + .rc-data-list {
    margin-top: 24px;
  }
  .rc-unit {
    border-bottom: var(--rc-border-base);
  }
  .col:first-child {
    padding-left: 0;
  }
  .col:last-child {
    padding-right: 0;
  }
  .rc-unit .row {
    align-items: top;
  }
  .rc-unit-cell {
    padding-top: 4px;
    padding-bottom: 6px;
    &.rc-unit-value {
      color: $gray-600;
      max-width: 800px;
      a {
        text-decoration: underline;
      }
    }
  }
  .card & {
    margin-bottom: 0;
    .rc-unit {
      border-radius: 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .card &:last-child .rc-unit:last-child {
    padding-bottom: 0;
    border-bottom: 0 none;
  }
}

.rc-process-list {
  .rc-unit {
    font-size: $font-size-sm;
  }
  .badge {
    text-indent: -9999px;
    padding: 0;
    left: 50%;
    margin-left: -14px;
    &-success {
      width: 14px;
      height: 14px;
      border-radius: 7px;
    }
    &-warning {
      width: 0;
      height: 0;
      background: none transparent;
      border-top: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 14px solid var(--warning);
      border-radius: 0;
    }
    &-secondary {
      width: 14px;
      height: 2px;
      border-radius: 1px;
      top: 6px;
    }
  }
}
