// ------------------------------------------
// LAYOUT FOR ADVANCED LIST
//-------------------------------------------

.rc-advanced-list {
  &--header {
    position: relative;

    .rc-container-title {
      padding-bottom: 0.75rem;
      justify-content: space-between;
    }
    .rc-container-search-filters {
      padding: 1rem;
      background-color: $gray-blue;
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;

      .rc-search-wrapper {
        height: unset;
        background-color: $gray-blue;
      }
    }
  }
  &--body {
    padding: 1rem;
    border-top: var(--rc-border-base);
    background: linear-gradient(0deg, white 0%, #fafafa 100%);

    h3,
    .h3 {
      font-size: 1.125rem;
      line-height: 1.1;
      font-weight: 400;
    }

    //à enlever quand tous les dropdowns seront changés.
    .dropdown,
    .dropdown-toggle {
      padding: 8px 12px 10px 12px;
      line-height: 1;
      &::before {
        display: none;
      }
      ul li {
        button {
          padding: 0.75rem 0.75rem;
          font-size: $font-size-sm;
          line-height: 1.1;
        }
      }
    }
    .rc-container-list-options {
      margin-top: 0;
      padding-bottom: 1rem;
      .rc-titles-count {
        text-transform: unset;
        font-weight: $font-weight-base !important;
        font-size: $h5-font-size;
      }
    }

    .rc-no-results {
      padding-top: 2rem;
      padding-bottom: 7rem;
      h2 {
        margin-bottom: 1rem;
      }

      ul.rc-list-bullet li {
        font-size: $font-size-lg;
      }
    }
  }
}
