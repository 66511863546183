// ------------------------------------------
// Boostrap Overrides
//-------------------------------------------
.btn-link {
  color: var(--black);

  &:hover {
    color: $gray-700;
    text-decoration: none !important;
  }
}

// ------------------------------------------
// Buttons - RC
//-------------------------------------------
//Button Login (Email/Google/Microsoft)
.rc-btn-login {
  display: flex;
  align-items: center;
  text-align: left;
  color: black;
  background-color: white;
  border: 2px solid $secondary;
  margin-bottom: 0.625rem;

  img {
    margin-right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
  }
  svg {
    margin-right: 1rem;
  }
  &:hover,
  &:focus {
    background-color: $lighter;
    color: black;
  }

  &.rc-btn-open-school {
    img {
      margin-left: -0.35rem;
      margin-right: 0.8rem;
      width: auto;
      height: 1.41rem;
    }
  }
}
