// Utility (login, pin & other compact form pages)
//___________________________________________________
.rc-util-layout {
  .main-container {
    padding-top: 40px;
    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }
  }
  .rc-page-header {
    margin-bottom: 16px;
    text-align: center;
    h1 {
      margin-bottom: 0;
    }
  }
  .rc-page-body {
    position: relative;
    .rc-intro {
      text-align: center;
      margin-bottom: 30px;
      strong {
        white-space: nowrap;
      }
    }
    form + p {
      margin-top: 3em;
      a {
        text-decoration: underline;
      }
    }
  }
  .rc-login {
    @extend .container-fluid;

    .rc-intro {
      text-align: center;
      margin-bottom: 2.25rem;
    }

    &--body {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      max-width: 22.5rem;
    }

    .rc-cta-wrapper {
      margin-top: 1.5rem;
    }
  }
  .rc-join-page {
    margin-top: -10px;
    .rc-page-footer {
      .btn {
        display: block;
        & + a {
          margin-top: 40px;
          color: var(--text-muted);
          text-decoration: underline;
        }
      }
    }
  }

  .rc-login-pin {
    .rc-intro {
      text-align: center;
      margin-bottom: 2.25rem;
    }
    &--body {
      position: relative;
      padding-right: 20px;
      padding-left: 20px;

      form {
        margin-left: auto;
        margin-right: auto;
        max-width: 22.5rem;
      }

      .has-error {
        border-radius: $border-radius;
        background: #ffecb4;
        border: 1px solid var(--warning);
        margin: 16px auto 16px;
        padding: 8px;
        text-align: center;
        .help-block {
          margin-bottom: 0;
        }
      }
    }

    &--footer {
      position: relative;
      text-align: center;
      margin-top: 40px;
    }
  }
}

// Landing
//___________________________________________________
.rc-landing-layout {
  .rc-flow-home {
    .rc-page-header {
      padding-top: 210px;
      .rc-app-brand {
        width: 266px;
        height: 242px;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -133px;
        text-indent: -999px;
        overflow: hidden;
        background: url(./assets/img/lg_flow_large.png) center top no-repeat
          transparent;
        background-size: 266px 242px;
      }
      .card {
        background: none transparent;
        .card-body {
          padding: 0;
        }
        .rc-action-group {
          padding-top: 16px;
          padding-bottom: 16px;
          justify-content: flex-end;
          .btn {
            margin-left: 16px;
          }
        }
      }
      .rc-app-filters {
        position: relative;
        z-index: 1;
      }
      .rc-search-wrapper {
        .rc-search {
          .magnify-glass-img {
            top: 4px;
          }
          .form-control {
            font-size: $h4-font-size;
          }
        }
      }
      .rc-filters-wrapper {
        border: 0 none;
        height: auto;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        .rc-filters {
          padding-bottom: 8px;
          &:after {
            display: none;
          }
        }
        .rc-action-group {
          background: #fff;
          margin-right: -15px;
          margin-left: -15px;
          padding-left: 15px;
          padding-right: 15px;
          border-top: var(--rc-border-base);
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
      .visible .rc-filters-wrapper {
        border-top: var(--rc-border-base);
      }
    }
    .rc-list-header {
      border-bottom: var(--rc-border-base);
      margin-bottom: 30px;
      padding-bottom: 12px;
      h2 {
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    h3 {
      font-size: $h5-font-size;
      margin-bottom: 10px;
    }
    .rc-page-body {
      margin-top: 40px;
      padding-top: 40px;
      border-top: var(--rc-border-base);
      &:before {
        content: "";
        display: block;
        background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(white),
          to(#fafafa)
        );
        background: linear-gradient(0deg, white 0%, #fafafa 100%);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 300px;
      }
      .card {
        margin-bottom: 32px;
        .card-body {
          padding: 8px 16px;
        }
        .list-group-item {
          cursor: pointer;
          padding-left: 0;
          padding-right: 0;
          border-bottom: 0 none;
          border-left: 0 none;
          border-right: 0 none;
          border-radius: 0;
          &:first-child {
            border-top: 0 none;
          }
        }
      }
    }
  }
}

// Default
//___________________________________________________

.rc-default-layout {
  .main-container {
    padding-top: 48px;
  }
  .rc-page-header {
    h1 {
      font-size: $h2-font-size;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
}

// Account
//___________________________________________________

.rc-account-layout {
  .main-container {
    padding-top: 48px;
  }
  .rc-page-header {
    h1 {
      font-size: $h2-font-size;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
  h2 {
    font-size: $h5-font-size;
  }
  .rc-list-header {
    margin-bottom: 0;
  }
}

// Home
//___________________________________________________

.rc-home-layout {
  .site-header {
    border-bottom: 0 none;
    background: none transparent;
  }
  .main-container:before {
    display: none;
  }
  .rc-page-header {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      width: 280px;
      height: 69px;
      background: url(./assets/img/lg_conduit_large.png) center top no-repeat
        transparent;
      background-size: 280px;
      text-indent: -999px;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        background-size: 595px;
        width: 595px;
        height: 146px;
        margin-right: -92px; //Compensate for gem width
      }
    }
    .rc-intro {
      font-size: $h4-font-size;
      line-height: 1.25;
      text-align: center;
      max-width: 700px;
    }
  }
  .card-deck {
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
  .card {
    margin-bottom: 16px;
    h2 {
      text-align: center;
    }
    .card-body {
      padding-bottom: 0;
    }
    .card-footer {
      padding-top: 0;
      background: none transparent;
      border: 0 none;
    }
  }
}

// Dashboard
//___________________________________________________
.rc-dashboard-layout {
  background: none transparent;
  .site-header {
    border-bottom: 0 none;
  }
  .main-container {
    min-height: calc(
      100% - var(--header-height)
    ); // Override the "1px" header border missing in this template
    background: none transparent;
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }
  .rc-drawer-wrapper:before {
    content: "";
    height: 1px;
    width: 100%;
    background: none #ddd;
    position: absolute;
    top: 78px;
    right: 0;
    left: 0;
  }
  .rc-drawer {
    top: 79px;
  }
  .rc-app-header {
    background: none transparent;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: -9px;
    &:after {
      display: none;
    }
  }
  .rc-app-body {
    position: relative;
    &:before {
      content: "";
      display: block;
      background: linear-gradient(
        0deg,
        rgba(#ffffff, 1) 0%,
        rgba(#fafafa, 1) 100%
      );
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 300px;
    }
  }
}

// Plain layout (error pages, other plain text pages)
//___________________________________________________
.rc-plain-layout {
  .main-container {
    padding-top: 40px;
    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }
  }
}
