@import "@/libscss/sites/syndication/variables/_bootstrap-custom"; @import "@/libscss/sites/syndication/variables/_globals";

.site-header {
  border-bottom: thin solid #dddddd;
  &:focus {
    outline: none;
  }
  .navbar {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    .rc-sidenav-trigger {
      display: block;
      padding: 0;
      margin-left: -5px;
      background: url(../../assets/img/ic_menu.svg) no-repeat center center
        transparent;
      background-size: 30px 20px;
      width: 40px;
      height: 30px;
    }
    .rc-user-trigger {
      display: flex;
      align-items: center;
      & > .nav-link {
        padding-right: 1.2em !important;
        // &::before {
        //   content: "";
        //   background: url(/img/ic_dropdown.svg) no-repeat center top
        //   transparent;
        //   display: block;
        //   position: absolute;
        //   top: 50%;
        //   right: 0;
        //   margin-top: -7px;
        //   width: 14px;
        //   height: 14px;
        // }
      }
      & + .rc-team-trigger {
        margin-left: 1.5em;
      }
    }
    .rc-team-trigger {
      display: flex;
      align-items: center;
      & > .nav-link {
        padding-right: 1.2em !important;
        span {
          display: block;
          font-size: small;
          font-weight: bold;
        }
        &::before {
          content: "";
          background: url(../../assets/img/ic_dropdown.svg) no-repeat center top
            transparent;
          display: block;
          position: absolute;
          top: 2em;
          right: 0;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
