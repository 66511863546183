@import "@/libscss/sites/syndication/variables/_bootstrap-custom"; @import "@/libscss/sites/syndication/variables/_globals";

.rc-upload-drag-zone {
  height: 10rem;
  background-color: #f9f9f9;
  border: 0.1875rem dashed rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;

  .rc-download {
    position: absolute;
    top: 50px;
    left: calc(50% - 15px);
  }

  .custom-file {
    height: 100%;

    label,
    input {
      height: 100%;
    }

    label {
      padding: 95px 0 0 0;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;

      &::after {
        opacity: 0;
      }

      &.dragging {
        color: #888;
        background-color: #f2f2f2;
      }
    }
  }

  .custom-file-input:disabled ~ .custom-file-label {
    background-color: transparent;
  }
}

.rc-uploads {
  min-height: 10rem;

  .list-group-item {
    padding: 1.25rem;
    background-color: #f9f9f9;
  }
  .progress {
    background-color: #eee;
  }
  .progress-bar {
    transition: all 1s ease;
  }
  .my-rounded-right {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }
  .my-rounded-left {
    z-index: 5;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .btn {
    padding: 0.1rem 0.5rem;
    &::before {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
  .progress-bar.bg-progress {
    background-color: var(--blue);
  }
  .btn-progress {
    color: white;
    background-color: var(--blue);
  }
}
