// ------------------------------------------
// Boostrap Variables
//-------------------------------------------

// Color system

//Grays
$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #ededed;
$gray-300: #dddddd;
$gray-400: #aaaaaa;
$gray-500: #888888;
$gray-600: #777777;
$gray-700: #333333;
$gray-800: #343a40;
$gray-900: #222;
$black: #000;

// Colors
$red: #ef0000;
$green: #158454;
$yellow: #ffc107;
$gray: $gray-600;
$blue: #1071c6;
$gray-blue: #dee2e6;

//Themes Colors
$primary: $red;
$secondary: $gray-600;
$success: $green;
$info: #aaaaaa;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$lighter: $gray-200;
$dark: $gray-800;

$theme-colors: (
  "lighter": $lighter
);

// Grid breakpoints
// We now have `@include media-breakpoint-up/down/only`.
// Now, instead of writing `@media (min-width: @screen-sm-min) { ... }`, you can write `@include media-breakpoint-up(sm) { ... }`.

// Legacy (à changer éventuellement)
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

//New
$grid-breakpoints: ();
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  ),
  $grid-breakpoints
);

//Components
$border-color: #ddd;
// Border radius (voir si ok)
$border-radius: 0.25rem;
$border-radius-lg: 0.25rem;
$border-radius-sm: 0.25rem;

$component-active-bg: #666;

//Fonts
$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.125); //18px
$font-size-sm: ($font-size-base * 0.875); //14px;

$font-weight-lighter: 400;
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$line-height-base: 1.35;

$h1-font-size: $font-size-base * 2.5; //40px
$h2-font-size: $font-size-base * 2.25; //36px
$h3-font-size: $font-size-base * 2; //32px
$h4-font-size: $font-size-base * 1.5; //24px
$h5-font-size: $font-size-base * 1.3215; //21px
$h6-font-size: $font-size-base * 1.125; //18px

$text-muted: #888888;
$small-font-size: 87.5%;

// Buttons + Forms
$input-btn-padding-y: 0.3125rem;

$input-btn-focus-width: 0.125rem;
$input-btn-focus-color: #16b3d9;

$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width #fff,
  0 0 0 calc(#{$input-btn-focus-width} * 2) $input-btn-focus-color;

$input-btn-padding-y-lg: 0.3125rem;
$input-btn-padding-x-lg: 1.25rem;

$input-btn-border-width: 2px;

// Buttons
$btn-padding-x: 1.25rem;
$btn-border-width: 0;
$btn-border-radius: 0.1875rem; //3px

// Forms
$input-bg: #fff;
$input-color: #000;
$input-border-color: #aaa;
$input-box-shadow: inset 0px 4px 5px -4px rgba(204, 204, 204, 1);

$input-focus-color: #000;
$input-focus-border-color: #aaa;

$form-group-margin-bottom: 1.5rem;

$custom-control-indicator-border-color: $component-active-bg;
$custom-control-indicator-focus-border-color: $component-active-bg;
$custom-control-indicator-disabled-bg: #888;
$custom-control-label-disabled-color: #888;

$form-feedback-valid-color: $input-border-color;
$form-feedback-invalid-color: #ef0b19;

// Cards
$card-spacer-y: 20px;
$card-spacer-x: 20px;
$card-border-width: 2px;
$card-border-color: rgb(221, 221, 221);
$card-bg: #fff;

// Badges
$badge-font-size: 0.75rem;
$badge-font-weight: normal;
$badge-padding-y: 0.3125rem; // 5px
$badge-padding-x: 0.625rem; // 10px
$badge-border-radius: 0.1875rem;

// Modals
$modal-title-line-height: 1.25;
$modal-content-border-width: 0;

$modal-header-padding: 1rem 1rem 0;
$modal-header-border-color: $gray-300;

$modal-footer-border-width: 1px;
$modal-footer-border-color: $modal-header-border-color;

//Alert
$alert-padding-y: 1.25rem;
$alert-padding-x: 1rem;
